import styled, { keyframes } from 'styled-components';
import { breakpoint } from '../../../../utils/breakpoints';
import { ButtonStyled } from "../Button/button.styled";
var fade = keyframes(["from{opacity:1;}to{opacity:0;}"]);
var fadeOut = keyframes(["from{opacity:0;}to{opacity:1;}"]);
var beforeAnim = keyframes(["from{opacity:1;width:0;transform:translateX(0px) rotate(45deg);transform-origin:left bottom;}to{opacity:1;width:50%;transform:translateX(0px) rotate(45deg);transform-origin:left bottom;}"]);
var afterAnim = keyframes(["from{opacity:1;width:0;transform:translateX(calc(50 / 8 * 1px)) rotate(-45deg);transform-origin:left bottom;}to{opacity:1;width:100%;transform:translateX(calc(50 / 8 * 1px)) rotate(-45deg);transform-origin:left bottom;}"]);
export var NewsLetterStyled = styled.div.withConfig({
  displayName: "newsletterstyled__NewsLetterStyled",
  componentId: "sc-1bded1o-0"
})(["", " h3{", ";color:#F4F4F4;}p{", ";color:#B9B9B9;}[class*='newsletter-newsletter_text-']{width:85%;", "{width:100%;}}[class*='message-root-']{display:block;margin:24px 0 0;font-size:1rem;line-height:1.5rem;color:#c9252d;padding:0;text-align:left;&:empty{display:none;}}.errorMessage-errorMessage-pas{margin:15px 0 0;font-family:'Avenir',\"sans-serif\";font-size:1rem;line-height:1.5rem;color:#c9252d;display:block;font-weight:normal;text-align:left;}input{", ";background:none;", "{", "}}label{display:none;}form{", ";align-items:baseline;animation-name:", ";animation-duration:.3s;animation-timing-function:ease-in-out;animation-fill-mode:forwards;", "{display:flex;flex-direction:row;}", "{margin-left:1rem;}}button{", " min-width:12.5rem;max-width:12.5rem;", "{min-width:auto;padding:0 2rem;min-height:48px;}span{", " text-decoration:none;}}.newsletter-success-wrapper{position:relative;display:flex;flex-direction:column;align-items:flex-start;", "{max-width:380px;}", "{max-width:unset;}h3{font-size:1rem;line-height:1.75rem;color:#FCBF7A;font-weight:400;margin-bottom:.25rem;}p{font-size:.875rem;line-height:1.25rem;color:#B9B9B9;margin-bottom:0;display:block;}animation-name:", ";animation-duration:.5s;animation-delay:3s;animation-timing-function:ease-in-out;animation-fill-mode:forwards;&.active{.checkmark{position:absolute;top:50%;left:80%;width:calc(30 * 1px);height:calc(30 * 1px);transform:translate(-2px,-15px);", "{left:88%;}", "{left:85%;}", "{left:80%;}&:before{position:absolute;left:0;bottom:calc(30 / 2.5 * 1px);height:calc(4 * 1px);width:0;background-color:#FFF;content:\"\";animation-name:", ";animation-duration:.3s;animation-delay:0s;animation-timing-function:ease-in-out;animation-fill-mode:forwards;}&:after{position:absolute;left:calc(30 / 6 * 1px);bottom:1px;height:calc(4 * 1px);width:0;background-color:#FFF;content:\"\";animation-name:", ";animation-duration:.3s;animation-fill-mode:forwards;animation-delay:.3s;animation-timing-function:ease-in-out;}}}}"], {
  "display": "block",
  "marginTop": "2.5rem",
  "@media (min-width: 1024px)": {
    "marginTop": "4rem",
    "paddingTop": "0.75rem"
  }
}, {
  "fontWeight": "700",
  "fontFamily": "HK Nova, sans-serif",
  "display": "block",
  "textTransform": "uppercase",
  "textAlign": "left",
  "@media (min-width: 1024px)": {
    "marginBottom": "0.5rem"
  }
}, {
  "fontFamily": "Avenir, sans-serif",
  "fontWeight": "400",
  "display": "none",
  "@media (min-width: 1024px)": {
    "display": "block",
    "marginBottom": "2rem"
  }
}, breakpoint('xl'), {
  "backgroundImage": "none",
  "borderWidth": "1px",
  "borderTopWidth": "0px",
  "borderLeftWidth": "0px",
  "borderRightWidth": "0px",
  "borderRadius": "0px",
  "paddingLeft": "0px"
}, breakpoint, {
  "@media (min-width: 1024px)": {
    "marginBottom": "-0.5rem",
    "paddingRight": "0.5rem"
  }
}, {
  "@media (min-width: 1024px)": {
    "display": "flex",
    "alignItems": "flex-end"
  }
}, fadeOut, breakpoint('md'), ButtonStyled, {
  "backgroundColor": "transparent",
  "textDecoration": "none",
  "display": "inlineFlex",
  "borderRadius": "100px",
  "fontFamily": "HK Nova",
  "padding": "16px 2.5rem",
  "fontWeight": "bold",
  "fontSize": "1.125rem",
  "margin": "0",
  "span": {
    "fontFamily": "HK Nova",
    "fontWeight": "bold"
  },
  "color": "white",
  "textTransform": "none",
  "boxShadow": "inset 0 0 0 2px #FFD5D7",
  "minHeight": "3.5rem",
  ":focus": {
    "boxShadow": "inset 0 0 0 100px #FFD5D7",
    "color": "#111111"
  },
  ":hover": {
    "boxShadow": "inset 0 0 0 100px #FFD5D7",
    "color": "#111111"
  },
  ":focus span": {
    "color": "#111111"
  },
  ":hover span": {
    "color": "#111111"
  },
  "lineHeight": "1.75rem",
  "whiteSpace": "nowrap",
  "paddingLeft": "2rem",
  "paddingRight": "2rem",
  "paddingTop": "0.5rem",
  "paddingBottom": "0.5rem",
  "@media (min-width: 1024px)": {
    "fontSize": "1.25rem",
    "padding": "18px 3.625rem",
    "span": {
      "fontSize": "1.25rem"
    },
    "marginLeft": "1rem"
  }
}, breakpoint, {
  "--tw-text-opacity": "1",
  "color": "rgb(var(--color-white) / var(--tw-text-opacity))",
  "fontSize": "0.875rem",
  "lineHeight": "1.25rem",
  "fontWeight": "500",
  "whiteSpace": "nowrap",
  "@media (min-width: 1024px)": {
    "fontSize": "1rem",
    "lineHeight": "1.5rem"
  }
}, breakpoint('md'), breakpoint('lg'), fade, breakpoint('md'), breakpoint('lg'), breakpoint('lg'), beforeAnim, afterAnim);