import styled from 'styled-components';
import { breakpoint } from '../../utils/breakpoints';
import { MountTypeFieldStyled, MountTypeStyled, TrimTypeFieldStyled } from './MountType/MountType.styled';
import { RadioButtonStyled } from "../RadioButton/RadioButtonGroup.styled";
import { BuyNowPopupStyled } from "../Product/Content/BuyNowPopup/BuyNowPopup.styled";
export var ProductCustomizeStyled = styled.div.withConfig({
  displayName: "ProductCustomizestyled__ProductCustomizeStyled",
  componentId: "sc-iea2xy-0"
})(["", ";", ";padding-left:0;padding-right:0;", "{max-width:1920px;margin:0 auto;}@media (min-width:1060px){", ";}", "{", ";}", "{", ";}.back-btn{", ";font-size:.938rem;line-height:1.625rem;", "{font-size:1.125rem;line-height:1.625rem;}> div{padding-right:1rem;}}a{", "}}"], {
  "paddingTop": "3rem",
  "paddingBottom": "3rem",
  "display": "flex",
  "width": "100%",
  "flexWrap": "wrap",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgba(245, 245, 245, var(--tw-bg-opacity))",
  "@media (min-width: 1024px)": {
    "paddingLeft": "2rem",
    "paddingRight": "2rem"
  }
}, {
  "@media (min-width: 1024px)": {
    "paddingTop": "5rem",
    "paddingBottom": "5rem",
    "justifyContent": "space-between"
  }
}, breakpoint('lg'), {
  "paddingLeft": "3rem",
  "paddingRight": "3rem"
}, breakpoint('xl'), {
  "paddingLeft": "5rem",
  "paddingRight": "5rem"
}, breakpoint('2xl'), {
  "paddingLeft": "9rem",
  "paddingRight": "9rem"
}, {
  "fontSize": "0.875rem",
  "lineHeight": "1.25rem",
  "fontFamily": "Avenir, sans-serif",
  "fontWeight": "700",
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "flex-start",
  "marginRight": "0.75rem"
}, breakpoint('lg'), {
  "textDecoration": "underline"
});
export var ProductCustomizeWizardStyled = styled.div.withConfig({
  displayName: "ProductCustomizestyled__ProductCustomizeWizardStyled",
  componentId: "sc-iea2xy-1"
})(["", ";", "{width:67%;}@media (min-width:1060px){", ";}", "{width:71%;", ";}", "{width:69%;", ";}", "{width:67%;}.top-button-wrapper{display:flex;justify-content:space-between;align-items:center;width:100%;padding:0 1.375rem 1rem;", "{padding:0 1.5rem 1.75rem;}", "{width:auto;.buy-now-btn{min-width:175px;font-size:.75rem;padding:0.75rem 1rem;height:2.5rem;", "{font-size:1rem;padding:0.938rem 2rem;height:3rem;min-width:264px;}}}}.back-btn{transition:all .3s ease-in-out;span{transition:all .15s ease-in-out;}&:hover{color:#E87C77;span{transform:translateX(-3px);}}svg{margin-right:.5rem;}}}"], {
  "width": "100%",
  "paddingLeft": ".5rem",
  "paddingRight": ".5rem",
  "@media (min-width: 1024px)": {
    "paddingLeft": "0px",
    "paddingRight": "2rem"
  }
}, breakpoint('lg'), {
  "paddingRight": "4rem"
}, breakpoint('xl'), {
  "paddingRight": "5rem"
}, breakpoint('2xl'), {
  "paddingRight": "8rem"
}, breakpoint('4xl'), breakpoint('md'), BuyNowPopupStyled, breakpoint('md'));
export var ProductCustomizeWizardWrapperStyled = styled.div.withConfig({
  displayName: "ProductCustomizestyled__ProductCustomizeWizardWrapperStyled",
  componentId: "sc-iea2xy-2"
})(["", ";"], {
  "borderRadius": "24px",
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(var(--color-white) / var(--tw-bg-opacity))",
  "paddingLeft": "1.375rem",
  "paddingRight": "1.375rem",
  "@media (min-width: 768px)": {
    "paddingLeft": "2.5rem",
    "paddingRight": "2.5rem",
    "paddingBottom": "2rem",
    "marginBottom": "3.5rem"
  },
  "@media (min-width: 1024px)": {
    "marginBottom": "0px"
  },
  "@media (min-width: 1280px)": {
    "paddingLeft": "4.688rem",
    "paddingRight": "4.688rem",
    "paddingBottom": "3.125rem"
  }
});
export var StepWrapperStyled = styled.div.withConfig({
  displayName: "ProductCustomizestyled__StepWrapperStyled",
  componentId: "sc-iea2xy-3"
})([""]);
export var StepNumberStyled = styled.span.withConfig({
  displayName: "ProductCustomizestyled__StepNumberStyled",
  componentId: "sc-iea2xy-4"
})(["", ";", ";font-size:.688rem;line-height:1.188rem;background:#343434;font-weight:700;"], {
  "--tw-text-opacity": "1",
  "color": "rgb(var(--color-white) / var(--tw-text-opacity))",
  "fontFamily": "Avenir, sans-serif",
  "borderRadius": "9999px",
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "center",
  "marginRight": "0.75rem",
  "@media (min-width: 1024px)": {
    "marginRight": "1rem"
  }
}, {
  "width": "1.5rem",
  "height": "1.5rem",
  "@media (min-width: 1280px)": {
    "width": "1.75rem",
    "height": "1.75rem"
  }
});
export var StepTitleStyled = styled.h3.withConfig({
  displayName: "ProductCustomizestyled__StepTitleStyled",
  componentId: "sc-iea2xy-5"
})(["transition:all .3s ease-in-out;"]);
export var ProductCustomizeItemStyled = styled.section.withConfig({
  displayName: "ProductCustomizestyled__ProductCustomizeItemStyled",
  componentId: "sc-iea2xy-6"
})(["", ";max-width:100%;&:last-of-type{margin-bottom:3rem;border-bottom:0;", "{margin-bottom:3.5rem;border-bottom:solid 1px #dfe1e2;}}&.disabled{", ";}", "{width:140px;@media (max-width:385px){width:132px;}", "{width:130px;}@media (min-width:1160px){width:140px;}", "{width:173px;}}", "{label{margin:0;}}h3{", ";font-size:1.125rem;line-height:1.75rem;display:none;", "{display:flex;}", "{font-size:1.875rem;line-height:2.5rem;}span{&:before{content:attr(data-step);", ";", ";}}}p{", " margin:0 0 1.25rem;font-size:0.938rem;line-height:1.563rem;", "{", " font-size:1.25rem;line-height:2.188rem;}}", "{p{margin:0;", "{margin:0 0 1.25rem;}}}ul{li{", " margin:0 0 1.25rem;font-size:0.938rem;line-height:1.563rem;", "{", " font-size:1.25rem;line-height:2.188rem;}}}fieldset{label{", " margin:0;font-size:0.938rem;line-height:1.563rem;", "{", " font-size:1.25rem;line-height:2.188rem;margin:0 0 1.25rem;}}}a{", " &:hover{color:#000;}}.selectWindowType{> div{margin:0;}}", "{", " &:last-of-type{margin-bottom:0;}label{text-align:left;margin:0;}}.MuiPaper-root{background:none;border-radius:unset;border:none;box-shadow:none;&.selected{.MuiButtonBase-root{.MuiAccordionSummary-content{", "{color:#000;}", "{color:#fff;background:#000;font-size:0;&:before{display:none;font:var(--fa-font-solid);content:\"\\f00c\";}svg{font-size:.625rem;}}}}}.MuiButtonBase-root{padding:0;.MuiAccordionSummary-content{width:100%;margin:0;display:flex;flex-direction:row;align-items:center;justify-content:space-between;padding:1rem 0;", "{display:flex;width:100%;justify-content:flex-start;}", "{display:block;margin:0;padding:0;color:#343434;}.edit-link{font-family:'Avenir',\"sans-serif\";font-size:.875rem;line-height:2.188rem;font-weight:500;text-decoration:underline;transition:all .3s ease-in-out;cursor:pointer;&:hover{text-decoration:none;}}&.Mui-expanded{", "{background:#000;}", "{color:#000;}}}}.MuiCollapse-root{.MuiAccordionDetails-root{padding:1rem 0 3rem;}}}}"], {
  "paddingTop": "0px",
  "paddingBottom": "0px",
  "borderBottomWidth": "1px",
  "--tw-border-opacity": "1",
  "borderColor": "rgb(var(--color-gray-300) / var(--tw-border-opacity))",
  "@media (min-width: 768px)": {
    "paddingTop": "3.5rem",
    "paddingBottom": "3.5rem"
  },
  "@media (min-width: 1280px)": {
    "paddingTop": "4rem",
    "paddingBottom": "4rem"
  }
}, breakpoint('md'), {
  "opacity": "0.4",
  "pointerEvents": "none"
}, RadioButtonStyled, breakpoint('sm'), breakpoint('xl'), TrimTypeFieldStyled, {
  "fontFamily": "HK Nova, sans-serif",
  "fontWeight": "700",
  "--tw-text-opacity": "1",
  "color": "rgb(var(--color-black) / var(--tw-text-opacity))",
  "fontSize": "1.25rem",
  "lineHeight": "1.75rem",
  "display": "flex",
  "paddingBottom": "2.25rem",
  "alignItems": "center",
  "@media (min-width: 1280px)": {
    "fontSize": "1.875rem",
    "lineHeight": "2.25rem"
  }
}, breakpoint('md'), breakpoint('lg'), {
  "--tw-bg-opacity": "1",
  "backgroundColor": "rgb(var(--color-black) / var(--tw-bg-opacity))",
  "fontSize": "0.875rem",
  "lineHeight": "1.25rem",
  "--tw-text-opacity": "1",
  "color": "rgb(var(--color-white) / var(--tw-text-opacity))",
  "fontFamily": "Avenir, sans-serif",
  "borderRadius": "9999px",
  "display": "flex",
  "alignItems": "center",
  "justifyContent": "center",
  "marginRight": "0.75rem",
  "@media (min-width: 1024px)": {
    "marginRight": "1rem"
  }
}, {
  "width": "1.5rem",
  "height": "1.5rem",
  "@media (min-width: 1280px)": {
    "width": "1.75rem",
    "height": "1.75rem"
  }
}, {
  "fontFamily": "Avenir, sans-serif",
  "fontWeight": "500"
}, breakpoint('lg'), {
  "fontWeight": "400"
}, MountTypeStyled, breakpoint('lg'), {
  "fontFamily": "Avenir, sans-serif",
  "fontWeight": "500"
}, breakpoint('lg'), {
  "fontWeight": "400"
}, {
  "fontFamily": "Avenir, sans-serif",
  "fontWeight": "500"
}, breakpoint('lg'), {
  "fontWeight": "400"
}, {
  "--tw-text-opacity": "1",
  "color": "rgba(12, 57, 129, var(--tw-text-opacity))"
}, MountTypeFieldStyled, {
  "marginBottom": "2.5rem",
  "@media (min-width: 1024px)": {
    "marginBottom": "3.5rem"
  }
}, StepTitleStyled, StepNumberStyled, StepWrapperStyled, StepTitleStyled, StepNumberStyled, StepTitleStyled);
export var SummaryBlockStyled = styled.div.withConfig({
  displayName: "ProductCustomizestyled__SummaryBlockStyled",
  componentId: "sc-iea2xy-7"
})(["", ";height:fit-content;width:calc(100% - 1rem);", "{width:33%;}", "{width:29%;}", "{width:31%;}", "{width:33%;}}"], {
  "marginLeft": "0.5rem",
  "marginRight": "0.5rem",
  "maxWidth": "100%",
  "@media (min-width: 1024px)": {
    "marginLeft": "0px",
    "marginRight": "0px",
    "position": "sticky",
    "top": "2.5rem"
  },
  "@media (min-width: 1280px)": {
    "width": "25%"
  }
}, breakpoint('lg'), breakpoint('xl'), breakpoint('2xl'), breakpoint('4xl'));
export var WizardDialogContentStyled = styled.div.withConfig({
  displayName: "ProductCustomizestyled__WizardDialogContentStyled",
  componentId: "sc-iea2xy-8"
})(["", " h3{", ";}p{", ";", "{font-size:17px;line-height:30px;}}button{", ";}a{", "}"], {
  "textAlign": "center",
  "padding": "3rem"
}, {
  "fontFamily": "HK Nova, sans-serif",
  "fontWeight": "700",
  "fontSize": "1.5rem",
  "lineHeight": "2rem",
  "marginBottom": "2rem",
  "@media (min-width: 1024px)": {
    "fontSize": "1.875rem",
    "lineHeight": "2.25rem"
  }
}, {
  "fontSize": "1rem",
  "lineHeight": "1.5rem",
  "fontFamily": "Avenir, sans-serif"
}, breakpoint('lg'), {
  "backgroundColor": "#175EB7",
  "textDecoration": "none",
  "display": "inlineFlex",
  "borderRadius": "100px",
  "fontFamily": "HK Nova",
  "padding": "18px 2rem",
  "fontWeight": "bold",
  "fontSize": "0.938rem",
  "margin": "0",
  "span": {
    "fontFamily": "HK Nova",
    "fontWeight": "bold"
  },
  "color": "white",
  "marginTop": "3rem"
}, {
  "display": "block",
  "marginTop": "1rem",
  "textDecoration": "underline",
  "fontWeight": "700",
  "cursor": "pointer"
});
export var WindowStyled = styled.div.withConfig({
  displayName: "ProductCustomizestyled__WindowStyled",
  componentId: "sc-iea2xy-9"
})(["", ";fieldset{", ";}label{", ";&:after{color:#E87C77;content:' *';display:inline;}}input{", ";width:100%;height:64px;border:solid 1px #E0E0E0;border-radius:32px;", "{max-width:30rem;}}}"], {
  "@media (min-width: 1024px)": {
    "marginBottom": "3rem"
  }
}, {
  "display": "flex",
  "flexDirection": "column"
}, {}, {
  "marginTop": "1.5rem",
  "paddingLeft": "2rem",
  "paddingRight": "2rem",
  "fontSize": "1.125rem",
  "lineHeight": "1.75rem"
}, breakpoint('lg'));
export var CloseButtonStyled = styled.button.withConfig({
  displayName: "ProductCustomizestyled__CloseButtonStyled",
  componentId: "sc-iea2xy-10"
})(["position:fixed;z-index:1400;background:#000;color:#fff;right:1.25rem;top:1.25rem;display:flex;justify-content:center;align-items:center;padding:10px;border-radius:50%;width:30px;height:30px;", " font-size:0;transition:all .3s ease-in-out;&:hover{background:#2A2A2A;}", "{font-size:0.75rem;line-height:1rem;width:85px;height:85px;right:3.125rem;top:2.4rem;}", "{width:100px;height:100px;right:3.125rem;top:1.875rem;font-size:1.188rem;line-height:2.375rem;}svg{stroke:#fff;", "{margin-left:5px;}}"], {
  "fontFamily": "HK Nova, sans-serif",
  "fontWeight": "500"
}, breakpoint('md'), breakpoint('lg'), breakpoint('md'));
export var OverlayStyled = styled.div.withConfig({
  displayName: "ProductCustomizestyled__OverlayStyled",
  componentId: "sc-iea2xy-11"
})(["width:100%;min-height:100vh;padding:1.875rem;margin:auto auto 5rem;max-width:1088px;", "{padding:3rem;}[class^='cmsBlock-root-']{margin:0;#tolstoy{margin:0 auto;height:220px !important;border-radius:10px;", "{height:540px !important;}}}h2{", " text-transform:none;margin:3rem 0;font-size:1.375rem;line-height:2.188rem;", "{margin:5.625rem 0;font-size:2.5rem;line-height:3.125rem;}}h3,h5{", " text-transform:none;margin:0 0 1.5rem;font-size:1.125rem;line-height:1.75rem;", "{font-size:1.875rem;line-height:2.375rem;}}p{", " margin:0 0 1.25rem;font-size:0.938rem;line-height:1.563rem;", "{font-size:1.375rem;line-height:2.375rem;}}a{color:#0c3982;text-decoration:underline;&:hover{text-decoration:none;}}ol,ul{li{", " margin:0;font-size:0.938rem;line-height:1.563rem;", "{font-size:1.375rem;line-height:2.375rem;}}}.table-wrapper{width:100%;margin:2rem 0;overflow-x:scroll;}table{tr{&:first-child{td{font-family:'HK Nova',sans-serif;font-weight:bold;font-size:1.125rem;line-height:2rem;border-right:none;", "{font-size:1.625rem;line-height:2.813rem;}}}&:last-child{td{border-bottom:none;}}td{height:auto !important;font-family:'Avenir','sans-serif';font-weight:500;font-size:1rem;line-height:1.25rem;padding:0.75rem;border-bottom:solid 1px rgba(18,18,18,50%);border-right:solid 1px rgba(18,18,18,50%);", "{font-size:1.25rem;line-height:1.563rem;padding:1.25rem;}&:last-child{border-right:none;}img{width:100%;padding:0;", "{width:80%;padding:0 20px;}}}}}h3{@media (max-width:767px){font-family:\"HK Nova\",sans-serif;padding:0.875rem 0;border-bottom:solid 1px #A7A7A7;margin-bottom:1.5rem;font-size:1.063rem;line-height:1.5rem;font-weight:500;text-align:left;}}"], breakpoint('lg'), breakpoint('lg'), {
  "fontFamily": "HK Nova, sans-serif",
  "fontWeight": "500"
}, breakpoint('lg'), {
  "fontFamily": "Avenir, sans-serif",
  "fontWeight": "700"
}, breakpoint('lg'), {
  "fontFamily": "Avenir, sans-serif",
  "fontWeight": "400"
}, breakpoint('lg'), {
  "fontFamily": "Avenir, sans-serif",
  "fontWeight": "400"
}, breakpoint('lg'), breakpoint('lg'), breakpoint('lg'), breakpoint('lg'));