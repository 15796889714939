import { onError } from '@apollo/client/link/error';
import getWithPath from 'lodash.get';
import setWithPath from 'lodash.set';
import * as Sentry from '@sentry/react';
var env = process.env.MAGENTO_ENV;
var logToSentry = error => {
  if (env !== 'development') {
    Sentry.captureException(new Error(error));
  }
};
export default function createErrorLink() {
  return onError(handler => {
    var {
      graphQLErrors,
      networkError,
      response
    } = handler;
    if (graphQLErrors) {
      graphQLErrors.forEach(_ref => {
        var {
          message,
          locations,
          path
        } = _ref;
        return console.log("[GraphQL error]: Message: ".concat(message, ", Location: ").concat(locations, ", Path: ").concat(path));
      });
      logToSentry(graphQLErrors);
    }
    if (networkError) {
      console.log("[Network error]: ".concat(networkError));
      logToSentry(networkError);
    }
    if (response) {
      var {
        data,
        errors
      } = response;
      var pathToCartItems;

      // It's within the GraphQL spec to receive data and errors, where
      // errors are merely informational and not intended to block. Almost
      // all existing components were not built with this in mind, so we
      // build special handling of this error message so we can deal with
      // it at the time we deem appropriate.
      errors.forEach((_ref2, index) => {
        var {
          message,
          path
        } = _ref2;
        if (message === 'Some of the products are out of stock.' || message === 'There are no source items with the in stock status' || message === 'The requested qty is not available') {
          if (!pathToCartItems) {
            pathToCartItems = path.slice(0, -1);
          }

          // Set the error to null to be cleaned up later
          response.errors[index] = null;
        }
      });

      // indicator that we have some cleanup to perform on the response
      if (pathToCartItems) {
        var cartItems = getWithPath(data, pathToCartItems);
        var filteredCartItems = cartItems.filter(cartItem => cartItem !== null);
        setWithPath(data, pathToCartItems, filteredCartItems);
        var filteredErrors = response.errors.filter(error => error !== null);
        // If all errors were stock related and set to null, reset the error response so it doesn't throw
        response.errors = filteredErrors.length ? filteredErrors : undefined;
      }
    }
  });
}