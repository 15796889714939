import styled, { keyframes } from 'styled-components';
import { breakpoint } from '../../../utils/breakpoints';
import { RadioButtonGroupStyled, RadioButtonImageStyled, RadioButtonStyled } from "../../RadioButton/RadioButtonGroup.styled";
var beforeAnim = keyframes(["from{opacity:1;width:0;transform:translateX(0px) rotate(45deg);transform-origin:left bottom;}to{opacity:1;width:50%;transform:translateX(0px) rotate(45deg);transform-origin:left bottom;}"]);
var afterAnim = keyframes(["from{opacity:1;width:0;transform:translateX(calc(50 / 8 * 1px)) rotate(-45deg);transform-origin:left bottom;}to{opacity:1;width:100%;transform:translateX(calc(50 / 8 * 1px)) rotate(-45deg);transform-origin:left bottom;}"]);
export var MountTypeStyled = styled.div.withConfig({
  displayName: "MountTypestyled__MountTypeStyled",
  componentId: "sc-2wigho-0"
})(["", ";p{font-size:.938rem;line-height:1.563rem;margin:0;", "{font-size:1.25rem;line-height:2.188rem;margin:0 0 1.25rem;}}}"], {}, breakpoint('lg'));
export var MountTypeFieldStyled = styled.div.withConfig({
  displayName: "MountTypestyled__MountTypeFieldStyled",
  componentId: "sc-2wigho-1"
})(["", ";&:not(:last-child){", ";}> label{", ";font-size:.938rem;line-height:1.563rem;padding-bottom:2rem;", "{font-size:1.25rem;line-height:2.188rem;padding-bottom:3.25rem;}}", "{", ";", "{", ";transition:border-color .3s ease-in-out;&:hover{", ";}&.buttonChecked{", ";.radio-content{", " .radio-text-content{.tick-icon{background:#0B865D;border:solid 1px #0B865D;.checkmark{&:before{animation-name:", ";animation-duration:.3s,.3s;animation-delay:0s,2s;animation-timing-function:ease-in-out;animation-fill-mode:forwards;}&:after{animation-name:", ";animation-duration:.3s,.3s;animation-fill-mode:forwards;animation-delay:.3s,2s;animation-timing-function:ease-in-out;}}}}}}.radio-content{", " .mount-type-only{", "}.radio-image-wrapper{", " .radio-image{", " &:hover{", "}}}.radio-text-content{", ";[class*='cmsBlock-root-']{", "}.tick-icon{position:absolute;top:.25rem;right:0;width:20px;height:20px;border:solid 1px #c0c0c0;border-radius:50%;transition:all .3s ease-in-out;.checkmark{position:absolute;top:50%;left:50%;width:calc(10 * 1px);height:calc(10 * 1px);cursor:pointer;transform:translate(-7px,-6px);&:before{position:absolute;left:2px;bottom:3px;height:2px;width:0;background-color:#fff;content:\"\";border-radius:10px;}&:after{position:absolute;left:0;bottom:0;height:2px;width:0;background-color:#fff;content:\"\";border-radius:10px;}}}.label-txt{", "}ul{", " li{", " &:last-of-type{", "}&::marker{", "}}}}}input{&:checked{+ div{&.radio-content{.radio-image{", "}}}}}}}", "{", " span{&.radio-image{", " &:hover{", "}}}.checkmark{display:none;}}}"], {
  "marginTop": "2.5rem",
  "@media (min-width: 1024px)": {
    "marginTop": "2rem"
  }
}, {
  "marginBottom": "2rem"
}, {
  "marginBottom": "1.75rem",
  "display": "block",
  "textAlign": "center",
  "@media (min-width: 640px)": {
    "marginBottom": "1rem",
    "textAlign": "left"
  }
}, breakpoint('lg'), RadioButtonGroupStyled, {
  "display": "flex",
  "flexDirection": "column",
  "gap": "2rem",
  "flexWrap": "wrap",
  "justifyContent": "space-around",
  "@media (min-width: 1024px)": {
    "gap": "1.5rem"
  }
}, RadioButtonStyled, {
  "width": "100%",
  "display": "flex",
  "borderRadius": "10px",
  "borderWidth": "1px",
  "borderStyle": "solid",
  "--tw-border-opacity": "1",
  "borderColor": "rgba(206, 206, 206, var(--tw-border-opacity))",
  "padding": "1.25rem",
  "cursor": "pointer",
  "@media (min-width: 768px)": {
    "paddingLeft": "2.313rem",
    "paddingTop": "1.063rem",
    "paddingBottom": "1.063rem",
    "paddingRight": "1.313rem",
    "minHeight": "225px"
  },
  "@media (min-width: 1536px)": {
    "minHeight": "247px"
  }
}, {
  "padding": "1.25rem",
  "@media (min-width: 768px)": {
    "paddingLeft": "2.25rem",
    "paddingTop": "1rem",
    "paddingBottom": "1rem",
    "paddingRight": "1.25rem"
  },
  "@media (min-width: 1280px)": {
    "--tw-border-opacity": "1",
    "borderColor": "rgba(11, 134, 93, var(--tw-border-opacity))",
    "borderWidth": "2px"
  }
}, {
  "--tw-border-opacity": "1",
  "borderColor": "rgba(11, 134, 93, var(--tw-border-opacity))",
  "borderWidth": "2px",
  "padding": "1.25rem",
  "@media (min-width: 768px)": {
    "paddingLeft": "2.25rem",
    "paddingTop": "1rem",
    "paddingBottom": "1rem",
    "paddingRight": "1.25rem"
  }
}, {
  "position": "relative"
}, beforeAnim, afterAnim, {
  "position": "relative",
  "width": "100%",
  "display": "flex",
  "flexDirection": "row",
  "flexWrap": "wrap",
  "alignItems": "flex-start",
  "@media (min-width: 768px)": {
    "flexWrap": "nowrap",
    "alignItems": "center"
  }
}, {
  "display": "block",
  "width": "100%",
  "fontFamily": "Avenir, sans-serif",
  "fontSize": "1.063rem",
  "lineHeight": "1.5rem",
  "marginLeft": ".625rem",
  "marginBottom": "1rem",
  "@media (min-width: 768px)": {
    "display": "none"
  }
}, {
  "width": "86px",
  "marginRight": "1rem",
  "@media (min-width: 640px)": {
    "width": "112px"
  },
  "@media (min-width: 768px)": {
    "marginRight": "3.75rem"
  },
  "@media (min-width: 1024px)": {
    "width": "122px"
  },
  "@media (min-width: 1280px)": {
    "width": "152px"
  }
}, {
  "width": "90px",
  "height": "90px",
  "marginBottom": "0px",
  "@media (min-width: 640px)": {
    "width": "112px",
    "height": "112px"
  },
  "@media (min-width: 1024px)": {
    "width": "122px",
    "height": "122px"
  },
  "@media (min-width: 1280px)": {
    "width": "152px",
    "height": "152px"
  }
}, {
  "--tw-border-opacity": "1",
  "borderColor": "rgba(206, 206, 206, var(--tw-border-opacity))",
  "borderWidth": "1px"
}, {
  "width": "59%",
  "@media (min-width: 768px)": {
    "width": "100%"
  }
}, {
  "margin": "0px"
}, {
  "display": "none",
  "fontFamily": "Avenir, sans-serif",
  "fontSize": "1.125rem",
  "lineHeight": "1.5rem",
  "@media (min-width: 768px)": {
    "display": "block"
  }
}, {
  "marginTop": "0px",
  "marginBottom": "0px",
  "paddingLeft": "1.125rem",
  "listStyleType": "disc",
  "@media (min-width: 768px)": {
    "marginTop": "1.5rem",
    "marginRight": "2rem"
  }
}, {
  "fontFamily": "Avenir, sans-serif",
  "fontSize": ".875rem",
  "lineHeight": "1.25rem",
  "fontWeight": "400",
  "marginBottom": ".5rem",
  "@media (min-width: 768px)": {
    "fontSize": "1rem",
    "lineHeight": "1.25rem",
    "marginBottom": "1.25rem"
  },
  "@media (min-width: 1536px)": {
    "fontSize": "1.125rem",
    "lineHeight": "1.5rem"
  }
}, {
  "marginBottom": "0px"
}, {
  "fontSize": "1.25rem"
}, {
  "--tw-border-opacity": "1",
  "borderColor": "rgba(206, 206, 206, var(--tw-border-opacity))",
  "borderWidth": "1px"
}, RadioButtonImageStyled, {
  "width": "90px",
  "height": "90px",
  "@media (min-width: 640px)": {
    "width": "110px",
    "height": "110px"
  },
  "@media (min-width: 1024px)": {
    "width": "120px",
    "height": "120px"
  },
  "@media (min-width: 1280px)": {
    "width": "150px",
    "height": "150px"
  }
}, {
  "marginBottom": "0px"
}, {
  "borderWidth": "0px"
});
export var TrimTypeFieldStyled = styled.div.withConfig({
  displayName: "MountTypestyled__TrimTypeFieldStyled",
  componentId: "sc-2wigho-2"
})(["", ";&:not(:last-child){", ";}> label{", ";font-size:.938rem;line-height:1.563rem;padding-bottom:2rem;", "{font-size:1.25rem;line-height:2.188rem;padding-bottom:3.25rem;}}", "{span{&.radio-image{", "}}}}"], {
  "marginTop": "2.5rem",
  "@media (min-width: 1024px)": {
    "marginTop": "2rem"
  }
}, {
  "marginBottom": "2rem"
}, {
  "marginBottom": "1.75rem",
  "display": "block",
  "textAlign": "center",
  "@media (min-width: 640px)": {
    "marginBottom": "1rem",
    "textAlign": "left"
  }
}, breakpoint('lg'), RadioButtonImageStyled, {
  "marginBottom": "0px"
});